import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { api } from './api.service';
import logo from './logo.png'
import Button from './components/button'
import FileView from './file-view';
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { FileUpload } from './components/file-upload';
import { GeneratedDoc } from './generated-doc';
import SignatureCanvas from 'react-signature-canvas'
import ReactToPrint from 'react-to-print';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="m-10">
      <GeneratedDoc signature={props.signature} readOnly={true} config={props.config} steps={props.steps} values={props.values}  ></GeneratedDoc>
    </div>
  );
});

export default function Induction() {

  const params = useParams();
  const [values, setValues] = useState({});
  const [config, setConfig] = useState();
  const [steps, setSteps] = useState();
  const [currentStep, _setCurrentStep] = useState();
  const [signatureStep, setSignatureStep] = useState(false);
  const sigRef = useRef(null);
  const [complete, setComplete] = useState(false)
  const [isSigned, setIsSigned] = useState(false);
  const [isEditingStep, setIsEditingStep] = useState(false)
  const componentRef = useRef();
  const [responseString, setResponseString] = useState("")

  const [submitting, setSubmitting] = useState(false);

  const setCurrentStep = (i) => {
    window.scrollTo(0, 0)
    _setCurrentStep(i);
  }

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/induction/${params.id}`).then(x => {
      setSteps(x.steps);
      setConfig(x.configuration);
    });
  }, [])

  const [tempSigData, setTempSigData] = useState()
  const submit = () => {
    setSubmitting(true);
    setTempSigData({ date: new Date(), data: sigRef.current?.getTrimmedCanvas().toDataURL() })
    api(`${process.env.REACT_APP_API_URL}/induction/${params.id}`, { data: { name: values.name, email: values.email, metaData: values.metaData, values: values, signature: { date: new Date(), data: sigRef.current?.getTrimmedCanvas().toDataURL() } } }).then(x => {
      setComplete(true);
      setResponseString(x.response);
      setSubmitting(false);
    });
  }

  const downloadDocument = () => {

    fetch(`${process.env.REACT_APP_API_URL}/induction/generate-file/${params.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({ data: { name: values.name, email: values.email, metaData: values.metaData, values: values, signature: tempSigData } })
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${values.name} Induction.pdf`,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });

  }

  const checkIfStepValid = (step, index) => {
    if (step.type === 0) {
      let i = 0;
      for (let q of step.questions) {
        if (q.required == "yes") {
          if (values[index + "_" + i] === undefined) return false;
        }
        if (q.required == "yes-answer-required") {
          if (!values[index + "_" + i]) return false;
        }
        i++;
      }
    }
    else if (step.type === 1) {
      return true;
    }
    else if (step.type === 2) {
      return true;
    }
    else if (step.type === 3) {
      if (step.minFiles && !values[index + "_files"]) return false
      if ((step.minFiles && values[index + "_files"]?.length < step.minFiles) || (step.maxFiles && values[index + "_files"]?.length > step.maxFiles)) {
        return false
      }
    }
    return true
  }

  if (!steps || !config) return <div></div>

  if (complete) return <>
    <div className="min-h-screen bg-neutral-100 flex flex-col">
      <div className="p-5 bg-white shadow font-semibold text-center text-xl text-neutral-800 mb-10">
        <div className="absolute top-0 left-0"><img className="w-44 h-16 object-cover" src={logo}></img></div>
        {config.name}
      </div>
      <div className="flex-1">
        <div className="max-w-3xl mx-auto bg-white shadow-lg">
          <div className="p-10 flex flex-col w-full">
            <div className="text-neutral-800 text-xl font-semibold">Submission Complete</div>
            <div className="text-neutral-500 text-sm">Your induction is now complete</div>
            <div className="text-neutral-600 text-md">{responseString}</div>
            <ReactToPrint trigger={() => {
              return <Button className="mx-auto px-5 py-2 mt-10 text-xl" text="Print Document"></Button>
            }} content={() => componentRef.current} />
            <Button onClick={() => downloadDocument()} className="mx-auto px-5 py-2 mt-10 text-xl" text="Download Document"></Button>
            <div style={{ display: "none" }}>
              <ComponentToPrint signature={tempSigData} readOnly={true} config={config} steps={steps} values={values} ref={componentRef} />
            </div>

          </div>
        </div>
      </div>
    </div>
  </>

  if (signatureStep) {
    return <>
      <div className="min-h-screen bg-neutral-100 flex flex-col">
        <div className="p-5 bg-white shadow font-semibold text-center text-xl text-neutral-800 mb-10">
          <div className="absolute top-0 left-0"><img className="w-44 h-16 object-cover" src={logo}></img></div>
          {config.name}
        </div>
        <div className="flex-1">
          <div className="max-w-3xl mx-auto bg-white shadow-lg">
            <div className="p-10 flex flex-col w-full">
              <div className="text-neutral-800 text-xl font-semibold">Review</div>
              <div className="text-neutral-500 text-sm">Review all information then sign to complete your induction</div>
              <GeneratedDoc editClick={(i) => { setCurrentStep(i); setSignatureStep(false); setIsEditingStep(true) }} config={config} steps={steps} values={values}></GeneratedDoc>
              <div className="text-neutral-800 text-xl font-semibold">Signature</div>
              <div className="text-neutral-500 text-sm">I confirm all information above is up to date and accurate</div>

              <div className="flex justify-between">
                <div className="text-neutral-400 text-xs mt-5">Sign Here</div>
                <div className=" text-neutral-400 text-xs mt-5 underline cursor-pointer" onClick={() => sigRef.current.clear()}>clear</div>

              </div>
              <SignatureCanvas canvasProps={{ className: "border border-gray-300" }} ref={sigRef} penColor='black' onEnd={() => setIsSigned(true)} />

              <Button onClick={() => submit()} disabled={!isSigned || submitting} className="mx-auto px-5 py-2 mt-10 text-xl" text={!submitting ? "Submit" : "Submitting"}></Button>

            </div>
          </div>
        </div>
      </div>

    </>
  }

  const step = steps[currentStep];

  if (!step) return <>
    <div className="min-h-screen bg-neutral-100 flex flex-col">
      <div className="p-5 bg-white shadow font-semibold text-center text-xl text-neutral-800 mb-10">
        <div className="absolute top-0 left-0"><img className="w-44 h-16 object-cover" src={logo}></img></div>
        {config.name}
      </div>
      <div className="flex-1">
        <div className="max-w-3xl mx-auto bg-white shadow-lg">
          <div className="p-10 flex flex-col w-full">
            <div className="text-neutral-800 text-xl font-semibold">General Information</div>
            <div className="text-neutral-500 text-sm">Complete these fields then press next</div>
            <div className="mt-5">
              <label className="block text-sm font-medium text-neutral-700 mt-2">
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
                type="text"
                className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <div className="mt-5">
              <label className="block text-sm font-medium text-neutral-700 mt-2">
                Email Address <span className="text-red-500">*</span>
              </label>
              <input
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
                type="text"
                className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <label className="block text-sm font-medium text-neutral-700 mt-10">
              If you don't have the information below please leave blank.
            </label>
            {config.metaDataFields?.map(x => <div className="mt-2">
              <label className="block text-sm font-medium text-neutral-700 mt-2">
                {x}
              </label>
              <input
                value={values?.metaData ? values?.metaData[x] : ''}
                onChange={(e) => setValues({ ...values, metaData: values.metaData ? { ...values.metaData, [x]: e.target.value } : { [x]: e.target.value } })}
                type="text"
                className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>)}

            {!isEditingStep && <Button onClick={() => setCurrentStep(0)} disabled={(!values.name || !values.email)} className="mx-auto px-5 py-2 mt-10 text-xl" text="Next"></Button>}
            {isEditingStep && <Button onClick={() => setSignatureStep(true)} disabled={(!values.name || !values.email)} className="mx-auto px-5 py-2 mt-10 text-xl" text="Back To Summary"></Button>}
          </div>
        </div>
      </div>
    </div>
  </>

  return (
    <>
      <div key={step._id} className="min-h-screen bg-neutral-100">
        <div className="p-5 bg-white shadow font-semibold text-center text-xl text-neutral-800 mb-10">
          <div className="absolute top-0 left-0"><img className="w-44 h-16 object-cover" src={logo}></img></div>
          {config.name}
        </div>
        <div className="flex-1">
          <div className="max-w-3xl mx-auto bg-white shadow-lg">
            <div className="p-10 flex flex-col w-full">
              {step.type == "0" && <>
                <div className="text-neutral-500 text-sm">Answer the questions then press next</div>

                {step.questions?.map((x, i) => <div key={x._id} className="my-3">
                  <span className="text-neutral-800 text-xl font-semibold">{x.title}</span><span className="text-red-500">{x.required == "yes" ? '*' : x.required == "yes-answer-required" ? '* Answer yes to proceed' : ''}</span>
                  <div className="text-neutral-500 text-sm">{x.details}</div>
                  {x.responseType === "yesno" && <>

                    <div className="relative flex items-start mt-2">
                      <div className="flex h-5 items-center">
                        <input
                          onChange={(e) => setValues({ ...values, [currentStep + "_" + i]: e.target.checked ? true : undefined })}
                          checked={values[currentStep + "_" + i]}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-700">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-2">
                      <div className="flex h-5 items-center">
                        <input
                          onChange={(e) => setValues({ ...values, [currentStep + "_" + i]: e.target.checked ? false : undefined })}
                          checked={values[currentStep + "_" + i] === false}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-700">
                          No
                        </label>
                      </div>
                    </div>

                  </>}
                  {x.responseType === "freetext" && <>
                    <input
                      value={values[currentStep + "_" + i]}
                      onChange={(e) => setValues({ ...values, [currentStep + "_" + i]: e.target.value })}
                      type="text"
                      className="mt-2 block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </>}
                  {x.responseType === "date" && <>
                    <input
                      value={values[currentStep + "_" + i]}
                      onChange={(e) => setValues({ ...values, [currentStep + "_" + i]: e.target.value })}
                      type="date"
                      className="mt-2 block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </>}
                  {x.responseType === "select" && <>
                    <select
                      value={values[currentStep + "_" + i]}
                      onChange={(e) => setValues({ ...values, [currentStep + "_" + i]: e.target.value })}
                      type="text"
                      className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    >
                      <option value=""></option>
                      {x.responseOptions.map(x => <option value={x.value}>{x.text}</option>)}
                    </select>
                  </>}
                  <hr className="my-3" />
                </div>)}

              </>}
              {step.type == "1" && <>
                <div className="text-neutral-500 text-sm">Read the document then press next</div>
                <div className="mt-10"><FileView filename={step.contentLocation}></FileView></div>
              </>}
              {step.type == "2" && <>
                <div className="text-neutral-500 text-sm">Watch the video then press next</div>
                <div className="mt-10"><ReactPlayer url={step.contentLocation} /></div>
              </>}
              {step.type == "3" && <>
                <div className="text-neutral-500 text-sm">Upload the required files then press next</div>

                <div className="text-neutral-700 text-md mt-10">{step.photoUploadInstructions}</div>
                <div className="text-neutral-500 text-sm mt-1">Min {step.minFiles} files</div>
                <div className="text-neutral-500 text-sm mt-1">Max {step.maxFiles} files</div>

                {values[currentStep + "_files"]?.map((x, i) => <>
                  <span className="text-neutral-800 text-lg mt-3">{x?.substring(13)} <span className="text-neutral-600 text-xs underline cursor-pointer" onClick={() => setValues({ ...values, [currentStep + "_files"]: values[currentStep + "_files"].filter((y, j) => j != i) })}>remove</span></span>
                </>)}
                <div className="my-5">
                  <FileUpload hideTitle={true} multiple={true} success={(x) => setValues({ ...values, [currentStep + "_files"]: values[currentStep + "_files"] ? values[currentStep + "_files"].concat(x.filesNames) : x.filesNames })}></FileUpload>

                </div>
              </>}
              <div className="flex justify-between">
                <div onClick={() => setCurrentStep(currentStep - 1)} className="cursor-pointer inline-flex items-center rounded-md border border-neutral-200 bg-white font-medium text-neutral-800 shadow-sm hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 px-5 py-2 mt-10 text-xl">Back</div>

                {!isEditingStep && <div onClick={() => checkIfStepValid(step, currentStep) ? (currentStep == (steps.length - 1) ? setSignatureStep(true) : setCurrentStep(currentStep + 1)) : {}} className={`${checkIfStepValid(step, currentStep) ? '' : 'opacity-20'} cursor-pointer inline-flex items-center rounded-md border border-neutral-200 bg-blue-600 font-medium text-white shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 px-5 py-2 mt-10 text-xl`}>Next</div>}
                {isEditingStep && <div onClick={() => checkIfStepValid(step, currentStep) ? setSignatureStep(true) : {}} className={`${checkIfStepValid(step, currentStep) ? '' : 'opacity-20'} cursor-pointer inline-flex items-center rounded-md border border-neutral-200 bg-blue-600 font-medium text-white shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 px-5 py-2 mt-10 text-xl`}>Back To Summary</div>}

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
