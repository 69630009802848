import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from "qrcode.react";
import CopyToClipboard from 'react-copy-to-clipboard';
import Confirm from './components/confirm';

const objectSingle = "configuration";
const objectMulti = "configurations";

export default function Configurations() {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [locations, setLocations] = useState([])
  const [share, setShare] = useState();
  
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/config/config/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config`).then(x => {
        setData(x);
        setDeleteId(null)
      });
    });
  }

  const submit = (values) => {
    api(`${process.env.REACT_APP_API_URL}/config`, { configuration: values }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config`).then(x => {
        setData(x);
      });
    });
  }
  
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/config`).then(x => {
      setData(x);
    });
    api(`${process.env.REACT_APP_API_URL}/config/locations`).then(x => {
      setLocations(x);
    });
  }, [])

  return (
    <>
      {edit && <EditModal close={() => setEdit(null)} submit={submit} edit={edit} locations={locations}></EditModal>}
      {share && <ShareModal share={share} close={() => setShare(null)} toast={toast}></ShareModal>}
      <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
      <ToastContainer />
      <div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div>
            <h2 className="capitalize flex-1 mx-auto text-lg font-medium leading-6 text-zinc-900 ">
              {objectMulti}
            </h2>
            <p className="text-sm text-neutral-500">These are your onboarding configurations. Create a new configuration for each different onboarding process you want to offer, for example one for each location or employee type.</p>
          </div>
          <div className="w-96">
            <button
              onClick={() => setEdit({ name: '' })}
              type="button"
              className="float-right capitalize inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Add {objectSingle}
            </button>
          </div>

        </div>

        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">

              <Table data={data.map(x => {
                return {
                  ...x,
                  edit: <span className="cursor-pointer underline" onClick={() => setEdit(x)}>Edit</span>,
                  steps: <Link to={`/admin/steps/${x._id}`} className="underline" onClick={() => setEdit(x)}>Configure Steps</Link>,
                  submit: <Link to={`/admin/submit-steps/${x._id}`} className="underline" onClick={() => setEdit(x)}>Configure Submit Process</Link>,
                  share: <span className="cursor-pointer underline" onClick={() => setShare(x)}>Share</span>,
                  deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>
                }
              })} columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },

                {
                  Header: 'Configure Steps',
                  accessor: 'steps',
                },
                {
                  Header: 'Configure Submit Process',
                  accessor: 'submit',
                },
                {
                  Header: 'Share',
                  accessor: 'share',
                },
                {
                  Header: 'Delete',
                  accessor: 'deleteCell',
                },
                {
                  Header: 'Edit',
                  accessor: 'edit',
                },
              ]}></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditModal = ({ close, submit, edit, locations }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    if (edit) {
      setValues(edit)
    }
  }, [])

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="w-full">
                  <h1 className="capitalize text-lg font-semibold text-neutral-900 text-center">
                    Manage {objectSingle}
                  </h1>
                  <div className="py-10 px-4 sm:px-6">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Name
                          </label>
                          <input
                            value={values.name}
                            onChange={(e) => setValues({ ...values, name: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label className="block text-sm font-medium text-zinc-700">
                          Location
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            value={values.location}
                            onChange={(e) => setValues({ ...values, location: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                          >
                            <option value={''}></option>
                            {locations.map(x => <option value={x._id}>{x.name}</option>)}
                          </select>
                        </div>
                      </div>

                      {values.metaDataFields?.map((x, index) => <div className="sm:col-span-5">
                        <div>
                          <label className="block text-sm font-medium text-zinc-700 mt-2">
                            Field Name
                          </label>
                          <input
                            value={x}
                            onChange={(e) => setValues({ ...values, metaDataFields: values.metaDataFields.map((y, i) => i === index ? e.target.value : y) })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                          />

                        </div>
                      </div>)}

                      <div className="sm:col-span-5">
                        <button
                          onClick={() => setValues({ ...values, metaDataFields: values.metaDataFields ? values.metaDataFields.concat([""]) : [""] })}
                          type="button"
                          className="rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-gray-800 shadow-sm hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-zinc-50 focus:ring-offset-2"
                        >
                          Add Metadata Field
                        </button>
                      </div>

                    </div>
                    <button
                      onClick={() => close()}
                      type="button"
                      className="mr-5 rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-blue-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-50 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => { submit(values); close() }}
                      type="button"
                      className=" rounded-md mt-5 border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
const ShareModal = ({ close, share, toast }) => {

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    console.log(canvas)
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${share.name} QR Code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="w-full">
                  <h1 className="capitalize text-xl font-semibold text-neutral-900 text-center">
                    Share Link
                  </h1>
                  <div className="py-10 px-4 sm:px-6 flex flex-col justify-center">
                    <div className="text-lg text-neutral-700 text-center">QR Code</div>
                    <div className="mx-auto mt-5"><QRCode size={200} id="qr-gen" value={`https://safetyfile.co.uk/induction/${share._id}`} /></div>
                    <div className="mx-auto mb-5"> <button
                      onClick={() => downloadQRCode()}
                      type="button"
                      className="rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-blue-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-50 focus:ring-offset-2"
                    >
                      Download
                    </button>
                    </div>
                    <hr />
                    <div className="text-lg text-neutral-700 text-center mt-5">Link</div>
                    <div className="mx-auto my-5">
                      <p className="text-xs text-neutral-400 text-center">copy to clipboard</p>
                      <CopyToClipboard text={`https://safetyfile.co.uk/induction/${share._id}`}
                        onCopy={() => toast.info("Copied To Clipboard!")}>
                        <span className="cursor-pointer underline text-xl">safetyfile.co.uk/{share._id}</span>
                      </CopyToClipboard>
                      <div className="text-center mt-10">
                        <a href={`https://safetyfile.co.uk/induction/${share._id}`} target={'_blank'} className="mt-5 cursor-pointer underline text-xl">View</a>
                      </div>


                    </div>
                    <div className="mx-auto">
                      <button
                        onClick={() => close()}
                        type="button"
                        className="rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-bg-blue-600 focus:ring-offset-2"
                      >
                        Close
                      </button>
                    </div>

                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
