import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';

const objectSingle = "user";
const objectMulti = "users";

export default function MasterAdminUsers() {

  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [edit, setEdit] = useState();
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/${objectSingle}/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/${objectMulti}`).then(x => {
        setData(x);
        setDeleteId(null)
      });
    });
  }
  const submit = (values) => {
    api(`${process.env.REACT_APP_API_URL}/admin/${objectSingle}`, { [objectSingle]: values }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/${objectMulti}`).then(x => {
        setData(x);
      });
    });
  }
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/admin/${objectMulti}`).then(x => {
      setData(x);
    });
    api(`${process.env.REACT_APP_API_URL}/admin/companies`).then(x => {
      setCompanies(x);
    });
  }, [])

  return (
    <>
      {edit && <EditModal close={() => setEdit(null)} submit={submit} edit={edit} companies={companies}></EditModal>}
      <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
      <div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <h2 className="capitalize flex-1 mx-auto text-lg font-medium leading-6 text-zinc-900 ">
            {objectMulti}
          </h2>
          <button
            onClick={() => setEdit({ name: '' })}
            type="button"
            className="capitalize inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Add {objectSingle}
          </button>
        </div>


        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              <Table data={data.map(x => {
                return {
                  ...x, userType: x.userType ?? 'manager',
                  lastLogin: <span className="text-xs">{x.lastLogin ? <span>{new Date(x.lastLogin).toDateString()}<br/>{new Date(x.lastLogin).toLocaleTimeString()}</span> : ''}</span>,
                  edit: <span className="cursor-pointer underline" onClick={() => setEdit(x)}>Edit</span>,
                  deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>
                }
              })} columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                },
                {
                  Header: 'Company',
                  accessor: 'company_name',
                },
                {
                  Header: 'Permissions',
                  accessor: 'userType',
                },
                {
                  Header: 'Last Login',
                  accessor: 'lastLogin',
                },
                {
                  Header: 'Edit',
                  accessor: 'edit',
                },
                {
                  Header: 'Delete',
                  accessor: 'deleteCell',
                },
              ]}></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditModal = ({ close, submit, edit, companies }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    if (edit) {
      setValues(edit)
    }
  }, [])

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="w-full">
                  <h1 className="capitalize text-lg font-semibold text-neutral-900 text-center">
                    Manage {objectSingle}
                  </h1>
                  <div className="py-10 px-4 sm:px-6">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Name
                          </label>
                          <input
                            value={values.name}
                            onChange={(e) => setValues({ ...values, name: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Email
                          </label>
                          <input
                            value={values.email}
                            onChange={(e) => setValues({ ...values, email: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            New Password
                          </label>
                          <input
                            value={values.password}
                            onChange={(e) => setValues({ ...values, password: e.target.value })}
                            type="password"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label className="block text-sm font-medium text-zinc-700">
                          User Permissions
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            value={values.userType}
                            onChange={(e) => setValues({ ...values, userType: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                          >
                            <option value="manager">Manager</option>
                            <option value="admin">Admin</option>
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label className="block text-sm font-medium text-zinc-700">
                          Company
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            value={values.company}
                            onChange={(e) => setValues({ ...values, company: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                            disabled={values._id}
                          >
                            <option value={''}></option>
                            {companies.map(x => <option value={x._id}>{x.name}</option>)}
                          </select>
                        </div>
                      </div>

                    </div>
                    <button
                      onClick={() => close()}
                      type="button"
                      className="mr-5 rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-blue-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-50 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => { submit(values); close() }}
                      type="button"
                      disabled={!values.company || !values.name || !values.email || (!values._id && !values.password)}
                      className="disabled:opacity-20 rounded-md mt-5 border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
